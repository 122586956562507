<template>
  <a-row>
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>

      <a-row class="flex-no__wrap wanted-search-input-wrapper" type="flex">
        <a-button type="primary" @click="openCreateModal">
          <a-icon type="plus" />Добавить
        </a-button>
      </a-row>
    </a-row>

    <a-table
      :columns="columns"
      :data-source="form"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 1600, y: 600 }"
      :row-key="(record) => record.id"
    >
      <template slot="id" slot-scope="item">
        <router-link
          :to="{
            name: 'reviews-docs',
            params: {
              cat_id: item.id
            }
          }"
          style="padding: 0"
          class="td-post-item"
          tag="span"
        >
          <b>{{ item.id }}</b>
        </router-link>
      </template>

      <template slot="order" slot-scope="item">
        <a-input-number
          v-model="item.position"
          style="width: 60px; margin-right: 10px"
          :min="1"
          @pressEnter="updatePosition(item, $event)"
        />
      </template>

      <template slot="name" slot-scope="item">
        <router-link
          :to="{
            name: 'reviews-docs',
            params: {
              cat_id: item.id
            }
          }"
          class="td-post-item"
          tag="span"
        >
          {{ item.title || "-" }}
        </router-link>
      </template>

      <template slot="status" slot-scope="item">
        <a-tag :color="item.is_active ? 'geekblue' : 'volcano'">
          {{ item.is_active ? $t("Published") : $t("NotPublished") }}
        </a-tag>
      </template>

      <template slot="created_at" slot-scope="item">
        {{ moment(item.created_at).format("LLL") }}
      </template>

      <template slot="updated_at" slot-scope="item">
        {{ moment(item.updated_at).format("LLL") }}
      </template>

      <template slot="operation" slot-scope="item">
        <a-row class="btns" type="flex" align="middle" justify="end">
          <a-button
            type="primary"
            class="edit-btn"
            @click="editOpenOrgType(item.id)"
          >
            <a-icon class="action-btns" type="edit" />
            <!--{{ $t("Edit") }}-->
          </a-button>
          <a-popconfirm
            cancel-text="Нет"
            ok-text="Да"
            title="Вы действительно хотите удалить?"
            @confirm="removeItem(item.id)"
          >
            <a-button type="danger">
              <a-icon class="action-btns" type="delete" />
              <!--{{ $t("Delete") }}-->
            </a-button>
          </a-popconfirm>
        </a-row>
      </template>
    </a-table>

    <a-row class="mt-1" style="padding-bottom: 15px">
      <a-pagination
        :key="limit"
        :current="page"
        :default-page-size="limit"
        :total="count || 0"
        @change="setPage"
      />
    </a-row>

    <a-modal
      :visible="modalVisible"
      title="Добавить"
      :closable="false"
      style="max-height: 500px; max-width: 900px"
      width="900px"
    >
      <a-row align="middle" class="flex-baseline sm-mb">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          Язык:
        </a-col>
        <a-col :span="12">
          <a-row align="middle" class="flex-baseline flex-wrap">
            <lang-tabs v-model="langTab" class="mb-1x" />
          </a-row>
        </a-col>
      </a-row>

      <a-row align="middle" class="flex-baseline">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          Название:
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-model="formOrgType['title_' + getKey]"
            placeholder="Введите название"
          />
        </a-col>
      </a-row>

      <a-row class="flex-baseline sm-mb">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          Позиция:
        </a-col>
        <a-col :span="12">
          <a-input
            v-model="formOrgType.position"
            type="number"
            name="position"
            placeholder="Позиция"
          />
        </a-col>
      </a-row>

      <a-row class="flex-baseline sm-mb">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          Статус:
        </a-col>
        <a-col :span="12">
          <a-switch v-model="formOrgType.is_active" />
        </a-col>
      </a-row>

      <template slot="footer">
        <a-button key="back" @click="modalVisible = false"> Закрыть </a-button>
        <a-button type="primary" @click="saveOrgType"> Сохранить </a-button>
      </template>
    </a-modal>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      form: [],
      formOrgType: {
        title_uz: "",
        title_oz: "",
        title_qr: "",
        title_en: "",
        title_ru: ""
      },
      modalVisible: false,
      langTab: $langPrefix,
      loading: false,
      columns: [
        {
          title: "ID",
          key: "id",
          width: "90px",
          fixed: "left",
          scopedSlots: { customRender: "id" }
        },
        {
          title: this.$t("Order"),
          key: "order",
          width: "90px",
          fixed: "left",
          scopedSlots: { customRender: "order" }
        },
        {
          title: this.$t("TableTitle"),
          key: "name",
          width: "50%",
          scopedSlots: { customRender: "name" }
        },
        {
          title: this.$t("Status"),
          key: "status",
          width: "20%",
          scopedSlots: { customRender: "status" }
        },
        {
          title: this.$t("TableCreatedDate"),
          key: "created_datetime",
          width: "30%",
          scopedSlots: { customRender: "created_at" }
        },
        {
          title: this.$t("TableLastUpdatedDate"),
          key: "updated_datetime",
          width: "30%",
          scopedSlots: { customRender: "updated_at" }
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          fixed: "right",
          scopedSlots: { customRender: "operation" }
        }
      ],
      page: 1,
      limit: 10,
      offset: 0,
      count: 0
    }
  },
  computed: {
    getKey() {
      return this.langTab
    }
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true
    }
  },
  methods: {
    setPage(pageNumber) {
      this.$router.push({ query: { ...this.$route.query, page: pageNumber } })
    },
    async fetchData() {
      this.loading = true
      this.offset = +this.$route.query.page || 1
      this.page = this.offset
      this.offset = (this.offset - 1) * this.limit
      try {
        const { data } = await this.$api.get(
          "/admin/common/document-category/list/",
          {
            params: {
              limit: this.limit,
              offset: this.offset
            }
          }
        )
        this.form = data.results
        this.count = data.count
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
      this.loading = false
    },
    async saveOrgType() {
      try {
        if (this.formOrgType.id) {
          await this.$api.patch(
            `/admin/common/document-category/${this.formOrgType.id}/update/`,
            this.formOrgType
          )
          this.$message.success("Успешно сохранено")
        } else {
          await this.$api.post(
            "/admin/common/document-category/create/",
            this.formOrgType
          )
          this.$message.success("Успешно добавлено")
        }
        await this.fetchData()
        this.modalVisible = false
      } catch (error) {
        this.$message.error("Ошибка при добавлении")
        console.error(error)
        this.modalVisible = false
      }
    },
    async editOpenOrgType(value) {
      const { data } = await this.$api.get(
        `/admin/common/document-category/${value}/update/`
      )
      this.formOrgType = data
      this.modalVisible = true
    },
    async removeItem(id) {
      this.loading = true
      try {
        await this.$api.delete(`/admin/common/document-category/${id}/delete/`)
        await this.fetchData()
        this.$message.success("Категория успешно удалено")
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    updatePosition(node, event) {
      this.loading = true
      this.$api
        .patch(`/admin/common/document-category/${node.id}/update/`, {
          position: event.target.value
        })
        .then(() => {
          this.loading = false
          this.$message.success("Позиция обновлена")
          this.fetchData()
        })
        .catch((err) => {
          this.loading = false
          this.$message.error(err.message)
        })
    },
    openCreateModal() {
      this.modalVisible = true
      this.formOrgType = {
        title_uz: "",
        title_ru: "",
        title_en: "",
        title_oz: "",
        title_qr: "",
        position: 1,
        is_active: false
      }
    }
  }
}
</script>
<style scoped>
.wanted-search-input-wrapper {
  justify-content: flex-end;
}
</style>
